/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.primary-btn {
    --background: #588c1d; // #0A395A;
    --color: #fff;
    width: 100%;
    --border-radius: 30px;
    height: 60px;
    text-transform: capitalize;
    font-size: 22px;
    font-family: 'Nunito-Bold';
    margin: 0;
    --box-shadow: none;
    --background-activated: transparent;
    letter-spacing: 0;

    &.button-disabled {
        --background: #9cda55; // #8F9CB3;
        opacity: 0.7;
    }
}

.secondary-btn {
    --background: #9cda55; // #70CFED;
    --color: #588c1d;
    width: 100%;
    --border-radius: 30px;
    height: 60px;
    text-transform: capitalize;
    font-size: 22px;
    font-family: 'Nunito-Bold';
    margin: 0;
    --box-shadow: none;
    --background-activated: transparent;
    letter-spacing: 0;
}

.border-btn {
    --background: #fff;
    --border-color: #588c1d; // #0A395A;
    --border-style: solid;
    --border-width: 2px;
    --color: #588c1d; // #0A395A;
    width: 100%;
    --border-radius: 30px;
    height: 60px;
    text-transform: capitalize;
    font-size: 22px;
    font-family: 'Nunito-Bold';
    margin: 0;
    --box-shadow: none;
    --background-activated: transparent;
    letter-spacing: 0;
}

.clear-btn {
    letter-spacing: 0;
    text-transform: capitalize;
    --color: #588c1d; // #0A395A;
    --color-activated: #588c1d; // #0A395A;
    --padding-top: 4px;
    --padding-bottom: 4px;
    --padding-end: 4px;
    --padding-start: 4px;
    margin: 0;
    font-size: 14px;
    font-family: 'Nunito-SemiBold';
    height: unset;
    --ripple-color: transparent;
    --background-activated: transparent;
}

ion-segment {
    overflow: hidden;
    background: #fff;
    margin: 0;

    ion-segment-button {
        text-transform: capitalize;
        font-size: 16px;
        font-family: 'Nunito-SemiBold';
        letter-spacing: 0;
        color: #588c1d; // #0A395A;
        height: 42px;
        min-height: unset;
        margin: 0;
        --indicator-height: 0px;
        --ripple-color: transparent;
        --padding-end: 6px;
        --padding-start: 6px;
        min-width: unset;
        max-width: unset;

        &.segment-button-checked {
            ion-label {
                margin: 0;
                position: relative;

                &::after {
                    position: absolute;
                    content: '';
                    bottom: -10px;
                    left: 0;
                    right: 0;
                    height: 8px;
                    border-radius: 5px;
                    background: #9cda55; // #70CFED;
                    min-width: 36px;
                    width: 60%;
                    margin: 0 auto;
                }
            }
        }

        ion-label {
            margin: 0;
        }
    }
}

.main-header {
    background: #fff;
    height: 70px;
    padding: 10px 16px;
    display: flex;

    ion-buttons {
        ion-button {
            color: #9cda55; // #70CFED;
            height: 36px;
            width: 36px;
            font-size: 24px;
            margin: 0;
            --padding-start: 2px;
            --padding-end: 2px;
        }
    }

    .header-logo {
        flex: 1;
        margin: 0 16px;

        ion-img {
            max-width: 140px;
            margin: 0 auto;
        }
    }

    .notify-icon {
        font-size: 32px;
        color: #9cda55; // #70CFED;
    }
}

// .custom-datePicker {
//     width: 100%;
//     padding: 0;
//     height: 52px;
//     border-radius: 30px;
//     background: #F3F3F3;
//     .mat-form-field-wrapper {
//         padding: 0;
//         height: 100%;
//         .mat-form-field-flex {
//             background-color: transparent;
//             padding: 6px 12px 6px 20px;
//             height: 100%;
//             .mat-form-field-infix {
//                 display: flex;
//                 align-items: center;
//                 justify-content: space-between;
//                 padding: 0;
//                 border: none;
//                 input {
//                     color: #588c1d; // #0A395A;
//                     font-size: 16px;
//                     font-family: "Nunito-Regular";
//                     &::placeholder {
//                         color: #0a3a5ccc;
//                     }
//                 }
//             }
//         }
//     }
//     .mat-form-field-underline {
//         display: none;
//     }
//     .mat-datepicker-toggle {
//         button {
//             color: #588c1d; // #0A395A;
//         }
//     }
// }

.custom-datePicker {
    width: 100%;

    .mat-mdc-form-field-infix {
        display: flex;
        align-items: center;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

mat-datepicker-content {
    .mat-calendar-body-selected {
        background-color: #588c1d; // #0A395A;
    }

    .mat-datepicker-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 12px;

        button {
            background-color: #588c1d; // #0A395A;
            color: #fff;
            width: 75px;
            margin: 0;
            border-radius: 4px;
            font-size: 14px;
            padding: 4px 14px 6px;
        }
    }
}

ion-header {
    .notify-btn {
        display: flex;
        align-items: center;
        position: relative;

        .notify-icon {
            font-size: 36px;
            color: #9cda55; // #70CFED;;
        }

        ion-badge {
            --background: #f70b0b;
            border-radius: 50%;
            border: 1px solid;
            top: -4px;
            position: absolute;
            min-width: 24px;
            right: -10px;
            font-size: 11px;
            line-height: 15px;
            --padding-top: 4px;
            --padding-bottom: 3px;
            --padding-end: 2px;
            --padding-start: 2px;
            letter-spacing: -0.1px;

            &.active {
                font-size: 10px;
                line-height: 14px;
                padding: 4px 0;
            }
        }
    }
}

ion-alert {
    .alert-radio-label.sc-ion-alert-ios {
        white-space: pre-wrap;
    }
}

.select-popover {
    ion-select-popover {
        ion-item {
            --padding-start: 15px;
            --inner-padding-end: 0;
            font-size: 16px;
            line-height: 18px;

            ion-label {
                text-overflow: unset !important;
                white-space: pre-wrap !important;
                overflow: unset !important;
            }
        }
    }
}

.bottom-sheet {
    min-height: 60vh;
    overflow: hidden !important;
}
